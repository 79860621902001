<template>
  <div class="publicLayout layout">
    <Loader class="position-absolute" v-if="isLoad" />
    <!--TOPBAR -->
    <div class="container">
      <!-- LOGIN FORM -->
      <div class="login">
        <div class="sidePanelForm text-light">
          <h3 class="text-center mt-4 mb-2 text-primary" v-if="false">
            <img
              v-lazy="require('@/assets/images/logo-full.png')"
              style="width: 250px; margin-bottom: 20px; margin-top: 10px"
            />
          </h3>
          <div class="layout-auth text-center">
            <div class="left">
              <img
                v-lazy="require('@/assets/images/mockup/rocket.png')"
                alt=""
              />
            </div>
            <div class="right">
              <h3>{{ $t("auth.sign_in") }}</h3>
              <label class="text-white text-left d-block" for="username"
                >{{ $t('auth.email') }} {{ $t('auth.or') }}
                {{ $t('auth.user_name') }}
              </label>
              <div class="input-group">
                <input
                  :style="showAuthentication ? 'opacity: 0.2;' : ''"
                  type="email"
                  class="auth-input"
                  v-model="users"
                  :placeholder="$t('auth.email')"
                  required
                />
              </div>
              <label class="text-white text-left d-block" for="username">{{
                $t("auth.password")
              }}</label>
              <div class="input-group">
                <input
                  :style="showAuthentication ? 'opacity: 0.2;' : ''"
                  :type="isShowPassLogin ? 'text' : 'password'"
                  class="auth-input"
                  v-model="password"
                  :placeholder="$t('auth.password')"
                  required
                  @keyup.enter="login"
                />
                <img
                  src="~@/assets/images/icons/icon-eye-close.png"
                  alt=""
                  class="img-eye"
                  v-if="!isShowPassLogin"
                  @click="isShowPassLogin = !isShowPassLogin"
                />
                <img
                  src="~@/assets/images/icons/icon-eye-open.png"
                  alt=""
                  class="img-eye"
                  v-else
                  @click="isShowPassLogin = !isShowPassLogin"
                />
              </div>
              <template v-if="showAuthentication">
                <label class="text-white text-left d-block" for="username">{{
                  $t("wallet.auth")
                }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="auth-input"
                    v-model="authCode"
                    :placeholder="$t('auth.please_enter_authentication_code')"
                    required
                    @keyup.enter="login"
                  />
                </div>
              </template>
              <div
                class="input-group d-flex justify-content-between mb-3 mt-3"
                v-if="false"
              >
                <a
                  class="text-right text-gray"
                  style="cursor: pointer"
                  @click="isForgotPanelShow = !isForgotPanelShow"
                  >{{ $t("auth.forgot_password") }}</a
                >
                <a
                  class="text-right text-primary"
                  style="cursor: pointer"
                  @click="isSendPanelShow = !isSendPanelShow"
                  >{{ $t("auth.resend_email") }}</a
                >
              </div>
              <div class="input-group mb-3 mt-3">
                <b-button
                  class="btn-logo w-100"
                  @click="login"
                  :disabled="!users || !password"
                >
                  {{ $t("auth.sign_in") }}
                </b-button>
              </div>
              <div
                class="input-group justify-content-center mb-3 mt-5"
                v-if="false"
              >
                <a
                  class="text-center text-secondary font-weight-bold text-sign-up"
                  style="cursor: pointer"
                  @click="isRegisterPanelShow = true"
                  v-if="!user"
                  >{{ $t("auth.sign_up") }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END LOGIN FORM -->
    </div>
    <!-- REGISTER FORM -->
    <SidePanel v-model="isRegisterPanelShow">
      <div class="login">
        <div class="sidePanelForm text-light" style="width: 80%; margin: auto">
          <h3 class="text-center mt-4 mb-2 text-primary">
            <img
              v-lazy="require('@/assets/images/logo-full.png')"
              style="width: 250px; margin-bottom: 20px; margin-top: 10px"
            />
          </h3>
          <div class="layout-auth text-center">
            <div class="left">
              <img
                v-lazy="require('@/assets/images/mockup/rocket.png')"
                alt=""
              />
            </div>
            <div class="right">
              <form @submit.prevent="onRegister()">
                <h3>{{ $t("auth.sign_up") }}</h3>

                <div class="d-flex flex-column align-self-center">
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.email")
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="auth-input"
                      v-model="register.email"
                      :placeholder="$t('auth.email')"
                      required
                    />
                  </div>
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.user_name")
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="auth-input"
                      v-model="register.username"
                      :placeholder="$t('auth.user_name')"
                      required
                    />
                  </div>
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.password")
                  }}</label>
                  <div class="input-group">
                    <input
                      :type="isShowPassRegister ? 'text' : 'password'"
                      class="auth-input"
                      v-model="register.password"
                      :placeholder="$t('auth.password')"
                      required
                    />
                    <img
                      src="~@/assets/images/icons/icon-eye-close.png"
                      alt=""
                      class="img-eye"
                      v-if="!isShowPassRegister"
                      @click="isShowPassRegister = !isShowPassRegister"
                    />
                    <img
                      src="~@/assets/images/icons/icon-eye-open.png"
                      alt=""
                      class="img-eye"
                      v-else
                      @click="isShowPassRegister = !isShowPassRegister"
                    />
                  </div>
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.password_confirmation")
                  }}</label>
                  <div class="input-group">
                    <input
                      :type="isShowPassConfirmRegister ? 'text' : 'password'"
                      class="auth-input"
                      v-model="register.password_confirm"
                      :placeholder="$t('auth.password_confirmation')"
                      required
                    />
                    <img
                      src="~@/assets/images/icons/icon-eye-close.png"
                      alt=""
                      class="img-eye"
                      v-if="!isShowPassConfirmRegister"
                      @click="
                        isShowPassConfirmRegister = !isShowPassConfirmRegister
                      "
                    />
                    <img
                      src="~@/assets/images/icons/icon-eye-open.png"
                      alt=""
                      class="img-eye"
                      v-else
                      @click="
                        isShowPassConfirmRegister = !isShowPassConfirmRegister
                      "
                    />
                  </div>
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.enter_sponsor")
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="auth-input"
                      v-model="register.sponsor"
                      :placeholder="$t('auth.enter_sponsor')"
                    />
                  </div>
                  <p class="text-left text-warning">
                    {{ $t('auth.noteRegister') }}
                  </p>
                  <b-button
                    class="btn-logo w-100"
                    type="submit"
                    :disabled="
                      !register.email ||
                      !register.username ||
                      !register.password ||
                      !register.password_confirm
                    "
                  >
                    {{ $t("auth.sign_up") }}
                  </b-button>
                  <div class="input-group justify-content-center mb-3 mt-5">
                    <a
                      class="text-center text-secondary font-weight-bold text-sign-up"
                      style="cursor: pointer"
                      @click="isRegisterPanelShow = false"
                      v-if="!user"
                      >{{ $t("auth.sign_in") }}</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
    <!-- END REGISTER FORM -->
    <!-- FORGOT FORM -->
    <SidePanel v-model="isForgotPanelShow">
      <div class="login">
        <div class="sidePanelForm text-light" style="width: 80%; margin: auto">
          <h3 class="text-center mt-4 mb-2 text-primary">
            <img
              v-lazy="require('@/assets/images/logo-full.png')"
              style="width: 250px; margin-bottom: 20px; margin-top: 10px"
            />
          </h3>
          <div class="layout-auth text-center">
            <div class="left">
              <img
                v-lazy="require('@/assets/images/mockup/rocket.png')"
                alt=""
              />
            </div>
            <div class="right">
              <h3 class="mb-4">{{ $t("auth.forgot_password") }}</h3>
              <form @submit.prevent="onForgot()">
                <div class="d-flex flex-column align-self-center">
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.email")
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="auth-input"
                      v-model="forgot.email"
                      :placeholder="$t('auth.enter_your_email')"
                      required
                    />
                  </div>
                  <b-button variant="primary" class="btn-logo" type="submit">
                    {{ $t("auth.forgot_password") }}
                  </b-button>
                  <div class="input-group justify-content-center mb-3 mt-5">
                    <a
                      class="text-center text-secondary font-weight-bold text-sign-up"
                      style="cursor: pointer"
                      @click="isForgotPanelShow = false"
                      v-if="!user"
                      >{{ $t("auth.sign_in") }}</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
    <!-- END FORGOT FORM -->
    <!-- RESEND FORM -->
    <SidePanel v-model="isSendPanelShow">
      <div class="login">
        <div class="sidePanelForm text-light" style="width: 80%; margin: auto">
          <h3 class="text-center mt-4 mb-2 text-primary">
            <img
              v-lazy="require('@/assets/images/logo-full.png')"
              style="width: 250px; margin-bottom: 20px; margin-top: 10px"
            />
          </h3>
          <div class="layout-auth text-center">
            <div class="left">
              <img
                v-lazy="require('@/assets/images/mockup/rocket.png')"
                alt=""
              />
            </div>
            <div class="right">
              <h3 class="mb-4">{{ $t("auth.resend_email") }}</h3>
              <form @submit.prevent="onResend()">
                <div class="d-flex flex-column align-self-center">
                  <label class="text-white text-left d-block" for="username">{{
                    $t("auth.email")
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="auth-input"
                      v-model="resend.email"
                      :placeholder="$t('auth.enter_your_email')"
                      required
                    />
                  </div>
                  <b-button class="btn-logo" type="submit">
                    {{ $t("auth.resend_email") }}
                  </b-button>
                  <div class="input-group justify-content-center mb-3 mt-5">
                    <a
                      class="text-center text-secondary font-weight-bold text-sign-up"
                      style="cursor: pointer"
                      @click="isSendPanelShow = false"
                      v-if="!user"
                      >{{ $t("auth.sign_in") }}</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
    <!-- END RESEND FORM -->
    <!-- DASHBOARD -->
    <router-link
      class="btn btn-primary topBarButton"
      to="/dashboard"
      v-if="user"
      >{{ $t("exchange.dashboard") }}</router-link
    >
    <!-- END DASHBOARD -->

    <!-- LOGOUT -->
    <b-button
      class="topBarButton"
      variant="primary"
      @click="logout"
      v-if="user"
      >{{ $t("exchange.logout") }}</b-button
    >
    <b-modal
      id="modal-notifi"
      title=""
      hide-footer
      body-class="p-0"
      header-class="p-0"
      modal-class="d-flex justify-content-center flex-column"
    >
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="notifi in NotifileList"
          :key="notifi.image"
          class="img-carousel"
          :img-src="notifi.image"
        ></b-carousel-slide>
      </b-carousel>
    </b-modal>
    <!-- END LOGOUT -->
  </div>
</template>

<script>
// @ is an alias to /src
import SidePanel from "@/components/SidePanel.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import Loader from "@/components/Loader.vue";
export default {
  name: "Home",
  data() {
    return {
      isLoginPanelShow: false,
      isRegisterPanelShow: false,
      isForgotPanelShow: false,
      isSendPanelShow: false,
      currentLanguage: "en",
      languageName: {
        vi: "Tiếng Việt",
        en: "English",
      },
      showAuthentication: false,
      users: "",
      password: "",
      authCode: "",
      register: {
        email: "",
        username: "",
        password: "",
        password_confirm: "",
        sponsor: "",
      },
      forgot: {
        email: "",
      },
      resend: {
        email: "",
      },
      slide: 0,
      sliding: null,
      isShowPassLogin: false,
      isShowPassRegister: false,
      isShowPassConfirmRegister: false,
    };
  },
  components: {
    SidePanel,
    Loader,
  },
  computed: {
    ...mapState(["user", "isLoad"]),
    ...mapGetters({
      NotifileList: "core/NotificationImage",
    }),
  },
  watch: {
    NotifileList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$bvModal.show("modal-notifi");
        }
      },
    },
    percent: {
      handler(newVal) {
        if (newVal) {
          // this.$store.commit('SET_PERCENT', 0)
        }
      },
    },
  },
  created() {
    this.$store.dispatch("core/req_getNotificationImages");
    if (this.$route.query.s && this.$route.query.m) {
      if (this.$route.query.s == 1) {
        this.$toastr.s(this.$route.query.m);
      }
      if (this.$route.query.s == 0) {
        this.$toastr.w(this.$route.query.m);
      }
      if (this.$route.query.s == -1) {
        this.$toastr.e(this.$route.query.m);
      }
    }
    const sponsor = this.$route.query.sponsor;
    if (sponsor) {
      this.register.sponsor = sponsor;
      this.isRegisterPanelShow = true;
    }
    const regis = this.$route.query.register;

    if (regis !== undefined) {
      this.isRegisterPanelShow = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "auth/LOGIN_SUCCESS":
          this.$toastr.s(
            this.$t('auth.login_successful'),
            this.$t('dashboard.success')
          );
          this.isLoginPanelShow = false;
          this.outload();
          this.$router.push({ name: "crash" });
          break;

        case "auth/REGISTER_SUCCESS":
          if (this.$store.state.core.success) {
            this.isLoginPanelShow = true;
            this.isRegisterPanelShow = false;
          }
          break;
        case "auth/REQUIRE_AUTH":
          this.showAuthentication = true;
          this.outload();
          break;
        case "auth/FORGOT_SUCCESS":
          if (this.$store.state.core.success) {
            this.isForgotPanelShow = false;
          }
          break;
        case "auth/RESEND_SUCCESS":
          if (this.$store.state.core.success) {
            this.isSendPanelShow = false;
          }
          break;
        case "auth/LOGIN_FAILED":
          this.$toastr.e(
            this.$t("auth.user_does_not_found"),
            this.$t("app.error")
          );
          this.outload();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    ...mapMutations(["onload", "outload"]),
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    login() {
      if (this.users && this.password) {
        this.onload();
        this.$store.dispatch("auth/req_PostLogin", {
          email: this.users,
          password: this.password,
          authCode: this.authCode,
        });
      }
    },
    onRegister() {
      this.onload();
      this.$store.dispatch("auth/req_PostRegister", this.register);
    },
    onForgot() {
      this.onload();
      this.$store.dispatch("auth/req_PostForgot", this.forgot);
    },
    onResend() {
      this.onload();
      this.$store.dispatch("auth/req_SendMail", this.resend);
    },
    logout() {
      this.$store.commit("auth/LOGOUT_SUCCESS");
    },
    changeLanguage(code) {
      this.currentLanguage = code;
      this.$i18n.locale = code;
    },

    getFlag(code) {
      // eslint-disable-next-line no-undef
      var images = require.context("@/assets/images/home/", false, /\.png$/);
      return images("./" + code + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

.btn-logo {
  letter-spacing: 0.05rem;
  position: relative;
  background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
  border-radius: 3px;
  color: #fff !important;
  min-width: 130px;
  transition: 0.3s ease-in-out;
  z-index: 1;
  border: 0;
  cursor: pointer;
  height: 50px;
  position: relative;
  font-weight: bold;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    filter: blur(21px);
    z-index: -1;
  }
}

.btn-logo.outline {
  background: #fff0 !important;
}

.btn-logo:hover {
  transform: scale(1.1);
}

.ml-10 {
  margin-left: 10px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.428571rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428572;
  color: #575757;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #27ab70;
  border-radius: 0.25rem;
  background: #00ff85;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  color: #fff;
  border-color: #27ab70;
  position: relative;
}

input.auth-input {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

/*** TOPBAR ***/
.publicLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .publicLayout {
    background: url("~@/assets/images/bg_ex_pc_2.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 768px) {
  .publicLayout {
    background: url("~@/assets/images/bg_ex_mb.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.login {
  .sidePanelForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    h3 {
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-transform: uppercase;
      font-size: 22px;
    }

    label {
      margin-bottom: 5px;
    }

    .text-sign-up {
      font-size: 22px;
    }
  }
}

.layout-auth {
  width: 100%;
  max-width: 1000px;
  border: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  transition: 0.5s;
  position: relative;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    width: 48%;

    @media (max-width: 768px) {
      display: none;
    }

    img {
      width: 250px;

      @media (max-width: 992px) {
        width: 180px;
      }
    }
  }

  .right {
    width: 48%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .layout-auth {
    padding: 10px;
  }

  .publicLayout.layout {
    .container {
      padding: 5px !important;
    }
  }
}

.topBar {
  height: 50px;
  padding: 7px 20px;
  line-height: 36px;
  background-image: linear-gradient(0deg, #101416, #42403f);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;

  .topBarButton {
    font-weight: bold;
    margin: 0 0.3em;
  }
}

/*** FOOTER SECTION ***/
.footerWrapper.homeSection {
  min-height: auto;
}

.footerWrapper {
  @include overlayBackground("~@/assets/images/home/footerBackground.jpg", 0);
  border-bottom: 30px solid $primaryColor;

  h4 {
    text-transform: uppercase;
    font-family: Roboto;
    letter-spacing: 0;
  }

  a {
    color: white;
    display: block;
    margin: 0.5em 0;
  }

  .languageSwitch {
    img {
      margin: 0.3em 0.5em 0.3em 0;
    }
  }

  hr {
    border-bottom: 1px solid $primaryColor;
    width: 100%;
  }
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .topBar {
    .topBarButton {
      line-height: 1em;
      font-size: 1.3em;
    }
  }
}

input.auth-input {
  border-radius: 3px;
  border: 0;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  display: block;
  height: calc(1.428572em + 0.857143rem + 5px);
  padding: 0.428571rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428572;
  background: rgba(49, 49, 49, 0.71);
  color: #fff;
  background-clip: padding-box;
  transition: border-color ease-in-out 0.15s;
}

.input-group {
  margin-bottom: 1.5rem;
  position: relative;
  .img-eye {
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
  }

  input {
    &::placeholder {
      font-style: italic;
    }
  }
}

.img-carousel {
  width: auto !important;
  max-width: 100%;
  max-height: 85vh;
}
</style>
