/* eslint-disable no-undef */
import axios from "axios";
import Vue from "vue";
import router from "../../router";
import VueCookies from "vue-cookies";
import store from "../../store";
Vue.use(VueCookies);
const token = window.$cookies.get("user_session");

var countSend = 1;
var completeSend = 1;

if (token != null && token.key != null) {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = "Bearer " + token.key;
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  // baseURL: process.env.VUE_APP_APIBETA_BUSTABIT,
  ContentType: "application/json",
  Accept: "application/json",
});

instance.interceptors.request.use(
  (config) => {
    if (config.url) {
      config.headers["accept-language"] = window.$cookies.get("language") ? window.$cookies.get("language") : 'en';
      store.commit("setLoading", { loading: true });
      countSend = countSend + 1;
      store.commit("setTask", { Total: countSend });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    store.commit("setTask", { Task: countSend });
    if (countSend - 1 <= completeSend) {
      store.commit("setLoading", { loading: false });
      completeSend = 1;
      countSend = 1;
      store.commit("setTask", { Task: completeSend });
      store.commit("setTask", { Total: countSend - 1 });
    } else {
      completeSend = completeSend + 1;
      store.commit("setTask", { Task: completeSend });
    }

    if (response.data.status) {
      if (response.data.message != null && response.data.message != "") {
        store.commit("core/SUCCESS_MESSANGE", response.data.message, {
          root: true,
        });
      }
      return response.data.data;
    } else {
      store.commit("core/ERROR_MESSANGE", response.data.message, {
        root: true,
      });
    }
  },
  function (error) {
    if (error.response) {
      store.commit("setTask", { Task: countSend });
      if (countSend - 1 <= completeSend) {
        store.commit("setLoading", { loading: false });
        completeSend = 1;
        countSend = 1;
        store.commit("setTask", { Task: completeSend });
        store.commit("setTask", { Total: countSend - 1 });
      } else {
        completeSend = completeSend + 1;
        store.commit("setTask", { Task: completeSend });
      }
      switch (error.response.status) {
        case 500:
          router.replace("/500");
          break;
        case 401:
          store.commit("auth/LOGOUT_SUCCESS");
          router.push("/login");
          break;
      }
    }
  }
);

export default instance;
