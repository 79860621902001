<template>
  <div class="privateLayout layout">
    <!-- MENU PANEL WRAPPER -->
    <div class="menuPanelWrapper">
      <!-- MENU MOBILE -->
      <div class="menu-mobile" v-if="widthL <= 992">
        <div class="menu-mobile-right">
          <div class="logout">
            <div class="box-logout" @click="logout">
              <img v-lazy="require('@/assets/images/icons/logout.png')" alt="logout" />
            </div>
          </div>
          <router-link :to="{ name: 'crash' }" class="mb-0 ml-4">ABC</router-link>
          <router-link :to="{ name: 'crash2' }" class="mb-0 ml-4">XYZ</router-link>
        </div>
      </div>
      <!-- END MENU MOBILE -->
      <!-- MENU PANEL -->
      <div class="menuPanel" :isOpen="isMenuShow">
        <!-- TOP BAR -->
        <div class="topBar d-lg-flex justify-content-between align-items-center">
          <!-- Topbar right -->
          <div class="topbar-right">
            <div class="logout">
              <div class="box-logout" @click="logout">
                <img v-lazy="require('@/assets/images/icons/logout.png')" alt="logout" />
              </div>
            </div>
            <router-link :to="{ name: 'crash' }" class="mb-0 ml-4">ABC</router-link>
            <router-link :to="{ name: 'crash2' }" class="mb-0 ml-4">XYZ</router-link>
          </div>

        </div>
        <!-- END TOP BAR -->
      </div>
      <!-- END MENU PANEL -->
    </div>
    <!-- END MENU PANEL WRAPPER -->

    <!-- MAIN CONTENT -->
    <div class="mainContent" id="app-reponsive">
      <Loader v-if="isLoad" />
      <router-view />
    </div>
    <!-- END MAIN CONTENT -->

    <b-modal id="language" title="" hide-footer hide-header body-class="p-0" header-class="p-0" size="sm">
      <div class="px-4 pt-4">
        <div class="header-modal mb-2">
          <h3 class="mb-0 text-uppercase">{{ $t("happy.language") }}</h3>
          <img :src="require('@/assets/images/icons/close-white.svg')" alt="" @click="$bvModal.hide('language')"
            class="close" />
        </div>
      </div>
      <div class="box-language">
        <div class="language-item" v-for="(value, key) in languageName" :key="'language: ' + value"
          @click="onSelectLanguage(key)" :class="langActive === key ? 'active' : ''">
          <img :src="require(`@/assets/images/lang/${key}.png`)" alt="" />
          {{ value }}
        </div>
      </div>
    </b-modal>
    <!-- END MODAL LANGUAGE -->
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { mapMutations, mapGetters } from "vuex";

// import moment from "moment";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      isMenuShow: false,
      currentLanguage: "gb-eng",
      languageName: {
        en: "English",
        vn: "Tiếng Việt",
        // kr: '한국어',
        ru: "русский",
        zh: "中文",
      },
      widthL: 0,
      langActive: "en",
      showBalance: false,
      balanceSelected: "BS",
      showMobileMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      ShowNotify: "ShowNotify",
      UserBalance: "userinfo/UserBalance",
      BalanceSocket: "socket/BalanceSocket",
      LoadingStatus: "LoadingStatus",
      CountNotify: "notify/CountNotify",
      ListNotifyEvent: "notify/ListNotifyEvent",
    }),
    isLoad() {
      return this.LoadingStatus;
    },
  },
  mounted() {
    this.langActive = this.$i18n.locale || "en";
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    window.addEventListener("resize", this.windowEvent);
    window.onresize = function () {
      this.widthL = window.innerWidth;
    };
  },
  created() {
    this.$store.dispatch("wallet/req_getPrice");
    this.$store.dispatch("notify/req_getNotificationSystem");
    this.$store.dispatch("notify/req_getNotificationEvent");
    this.$store.dispatch("notify/req_getNotificationCount");
    const tokenUser = window.$cookies.get("user_session");
    if (tokenUser) {
      this.$store.dispatch("userinfo/req_getInfo");
    }
  },

  methods: {
    ...mapMutations(["onload", "outload"]),
    onShowNotification() {
      this.$store.dispatch("notify/req_getUpdateNotification");
      this.$store.commit("toggleNotify");
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    onSelectLanguage(lang) {
      this.langActive = lang;
      this.$cookies.set("language", lang);
      this.$i18n.locale = lang;

      setTimeout(() => {
        window.location.reload();
      }, 200);
      this.$bvModal.hide("language");
    },

    logout() {
      this.$store.commit("auth/LOGOUT_SUCCESS");
      this.$router.push("/login");
    },
  },
  watch: {
    $route() {
      // close menu when active new route
      this.showMobileMenu = false;
    },
    ListNotifyEvent: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.$bvModal.show("notification-event");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*** MENU PANEL ***/
.menuPanelWrapper {
  height: 50px;
  width: 100%;
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--header-1,
      linear-gradient(180deg, #000000, rgba(49, 49, 49, 0.71)),
    );
  z-index: 2000;

  @media (max-width: 992px) {
    position: relative;
  }

  .menuPanel {
    background: linear-gradient(180deg, #000000, rgba(49, 49, 49, 0.71));
    max-width: 480px;
    width: 100%;
    position: fixed;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    right: -480px;
    transition: all 0.3s;
    padding: 0 1em;
    z-index: 2000;

    /*** TOPBAR ***/
    .topBar {
      height: 100%;

      .topbar-right {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .langue {
          margin-right: 30px;
          position: relative;

          .box-langue {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, #388ede 0%, #380070 100%);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
              height: 20px;
              width: auto;
            }
          }

          .box-langue-drop {
            position: absolute;
            cursor: pointer;
            top: 45px;
            left: 0px;
            width: 40px;
            height: 40px;
            background: #ffffff;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
              height: 20px;
              width: auto;
            }

            &:hover {
              background: linear-gradient(180deg, #388ede 0%, #380070 100%);
            }
          }
        }

        .notification {
          position: relative;
          margin-right: 30px;

          .box-notification {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: linear-gradient(128.55deg,
                rgba(255, 0, 0, 0.63) 11.79%,
                rgba(0, 0, 0, 0.92) 84.59%);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
              height: 20px;
              width: auto;
            }
          }

          .notification-count {
            position: absolute;
            top: -3px;
            left: -18px;
            width: 29px;
            height: 16px;
            background: #f51055;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              margin-bottom: 0px;
              font-size: 10px;
              line-height: 100%;
            }
          }
        }

        .setting {
          margin-right: 30px;

          .box-setting {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, #00a3ff 0%, #041b6c 100%);
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
              height: 20px;
              width: auto;
            }
          }
        }

        .balance {
          margin-right: 30px;

          .box-balance {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;

            .balance-amount {
              display: flex;
              align-items: center;
              position: relative;

              p {
                margin-bottom: 0px;
                margin-left: 5px;
                font-size: 18px;
                line-height: 100%;

                &.currency {
                  color: #6a6a6a;
                }

                &.amount {
                  font-weight: bold;
                  color: #ffc700;
                }
              }

              .balance-amount-dropdown {
                position: absolute;
                top: 25px;
                left: 0px;
                background: linear-gradient(180deg, #081e43 0%, #01060f 100%);
                border: 1px solid #00c2ff;
                border-radius: 7px;
                z-index: 1;

                .balance-amount-dropdown-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  position: relative;
                  padding: 10px;

                  &:last-child {
                    border-top: 1px solid #00c2ff;
                    padding-top: 10px;
                  }

                  p {
                    color: #ffffff;
                    font-size: 13px;
                    margin-bottom: 0px;

                    &.amount {
                      font-weight: bold;
                      color: #ffc700;
                    }
                  }

                  img {
                    width: 15px;
                  }
                }
              }
            }

            .balance-action {
              button {}
            }
          }
        }

        .profile {
          margin-right: 30px;
          position: relative;
          cursor: pointer;

          .box-profile {
            img {
              width: 40px;
              height: auto;
            }
          }

          .profile-online {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #24ff00;
          }
        }

        .logout {
          .box-logout {
            cursor: pointer;

            img {
              height: 23px;
            }
          }
        }
      }
    }
  }

  .menuPanel[isOpen="true"] {
    right: 0;

    .closeMenu {
      display: block;
    }
  }

  .menu-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    height: 100%;

    @media (max-width: 376px) {
      padding: 0px 15px;
    }

    .menu-mobile-left {
      height: 100%;

      img {
        height: 100%;
      }
    }

    .langue {
      margin-right: 30px;
      position: relative;

      .box-langue {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: linear-gradient(180deg, #388ede 0%, #380070 100%);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          height: 20px;
          width: auto;
        }
      }

      .box-langue-drop {
        position: absolute;
        cursor: pointer;
        top: 45px;
        left: 0px;
        width: 40px;
        height: 40px;
        background: #ffffff;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          height: 20px;
          width: auto;
        }

        &:hover {
          background: linear-gradient(180deg, #388ede 0%, #380070 100%);
        }
      }
    }

    .menu-mobile-right {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .balance {
        margin-right: 20px;

        @media (max-width: 376px) {
          margin-right: 10px;
        }

        .box-balance {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;

          .balance-amount {
            display: flex;
            align-items: center;
            position: relative;

            p {
              margin-bottom: 0px;
              margin-left: 5px;
              font-size: 14px;
              line-height: 100%;

              &.currency {
                color: #6a6a6a;
              }

              &.amount {
                font-weight: bold;
                color: #ffc700;
              }
            }

            .balance-amount-dropdown {
              position: absolute;
              top: 25px;
              left: -55px;
              width: 200px;
              background: linear-gradient(180deg, #081e43 0%, #01060f 100%);
              border: 1px solid #00c2ff;
              border-radius: 7px;

              .balance-amount-dropdown-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 10px;

                &:last-child {
                  border-top: 1px solid #00c2ff;
                  padding-top: 10px;
                }

                &:nth-child(2) {
                  border-top: 1px solid #00c2ff;
                  padding-top: 10px;
                }

                p {
                  color: #ffffff;
                  font-size: 13px;
                  margin-bottom: 0px;

                  &.amount {
                    font-weight: bold;
                    color: #ffc700;
                  }
                }

                img {
                  width: 15px;
                }
              }
            }
          }

          .balance-action {
            button {
              font-size: 14px;
            }
          }
        }
      }

      .profile {
        margin-right: 0px;
        position: relative;
        cursor: pointer;

        .box-profile {
          img {
            width: 40px;
            height: auto;
          }
        }

        .profile-online {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #24ff00;
        }
      }
    }
  }

  .menu-mobile-bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(90.13deg, #00041b 0%, #000937 100%);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .crash {
      position: relative;
      text-decoration: none;

      ::after {
        position: absolute;
        content: "";
        top: 0px;
        left: -10px;
        width: 90px;
        height: 90px;
        background: linear-gradient(180deg,
            #07f0ff 0%,
            #0047ff 48.96%,
            #00b3ff 70.44%);
        filter: blur(19px);
        z-index: -1;
        border-radius: 50%;
      }

      p {
        font-weight: bold;
        color: #ffffff !important;
      }
    }

    .menu {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: linear-gradient(128.55deg,
          rgba(0, 209, 255, 0.63) 11.79%,
          rgba(28, 214, 255, 0.92) 84.59%);
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      img {
        height: 20px;
        width: auto;
      }
    }

    .langue {
      position: relative;

      .box-langue {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: linear-gradient(180deg, #388ede 0%, #380070 100%);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          height: 20px;
          width: auto;
        }
      }

      .box-langue-drop {
        position: absolute;
        cursor: pointer;
        top: -45px;
        left: 0px;
        width: 40px;
        height: 40px;
        background: #ffffff;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          height: 20px;
          width: auto;
        }

        &:hover {
          background: linear-gradient(180deg, #388ede 0%, #380070 100%);
        }
      }
    }

    .notification {
      position: relative;

      .box-notification {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: linear-gradient(128.55deg,
            rgba(255, 0, 0, 0.63) 11.79%,
            rgba(0, 0, 0, 0.92) 84.59%);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          height: 20px;
          width: auto;
        }
      }

      .notification-count {
        position: absolute;
        top: -3px;
        left: -18px;
        width: 29px;
        height: 16px;
        background: #f51055;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-bottom: 0px;
          font-size: 10px;
        }
      }
    }

    .setting {
      .box-setting {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: linear-gradient(180deg, #00a3ff 0%, #041b6c 100%);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          height: 20px;
          width: auto;
        }
      }
    }
  }
}

/*** MAIN CONTENT ***/
.mainContent {
  background: linear-gradient(180deg, #000000, rgba(49, 49, 49, 0.71));
  background-attachment: fixed;
  padding: 0px;
  padding-top: 0px;
  min-height: 100vh;

  @media (min-width: 992px) {
    padding-top: 50px;
  }
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {

  /*** MENU PANEL WRAPPER ***/
  .menuPanelWrapper {
    height: 75px;

    .closeBar,
    .openMenu,
    .closeMenu {
      display: none;
    }

    .menuPanel {
      max-width: 100%;
      top: 0;
      right: initial;
      left: 0;
      height: 75px;
      padding: 5px 20px;
      overflow: initial;

      /*** TOPBAR ***/
      .topBar {
        .quickCharge {
          height: 40px;
          margin: 10px;
        }

        .navLink {
          width: max-content !important;
        }
      }

      /*** SIDEBAR ***/
      .sideBar:hover {
        overflow-y: auto;
        // padding-right: 0;
      }

      .sideBar {
        position: fixed;
        width: 105px;
        top: 75px;
        color: #fff;
        left: 0;
        padding: 10px 0px 30px;
        z-index: 1;
        font-weight: 300;
        background: linear-gradient(90.13deg, #00041b 0%, #000937 100%);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        z-index: 99999;

        .navLink {
          margin: 10px 0;
        }

        h3 {
          font-weight: 500;
          margin-bottom: 30px;
        }

        .sideBar-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          width: 100%;
          padding: 10px 0px;
          margin-bottom: 15px;
          font-size: 14px;
          cursor: pointer;
          position: relative;
          text-decoration: none;

          img {
            margin-bottom: 10px;
          }

          p {
            margin-bottom: 0px;
            color: rgba(2, 225, 255, 0.25);
            text-shadow: 0px 0px 1px #ffffff8e;
          }

          &.crash {
            background: linear-gradient(270deg,
                #116cd7 0%,
                rgba(49, 115, 193, 0) 92.71%);
            border-bottom: 2px solid #02e1ff;

            img {
              width: 75px;
              height: auto;
              margin-bottom: 10px;
              filter: none !important;
            }

            p {
              color: #ffffff;
              font-size: 18px;
            }
          }

          &:hover {
            &::after {
              position: absolute;
              content: "";
              top: 0px;
              left: 0;
              right: 0;
              width: 80px;
              height: 64px;
              background: linear-gradient(180deg,
                  #07f0ff 0%,
                  #0047ff 48.96%,
                  #00b3ff 70.44%);
              filter: blur(17px);
              z-index: -1;
              border-radius: 50%;
              bottom: 0;
              margin: auto;
            }

            p {
              color: #ffffff;
            }

            img {
              filter: brightness(0) invert(1);
            }
          }

          &.router-link-active {
            &::after {
              position: absolute;
              content: "";
              top: 0px;
              left: 0;
              right: 0;
              width: 80px;
              height: 64px;
              background: linear-gradient(180deg,
                  #07f0ff 0%,
                  #0047ff 48.96%,
                  #00b3ff 70.44%);
              filter: blur(17px);
              z-index: -1;
              border-radius: 50%;
              bottom: 0;
              margin: auto;
            }

            p {
              color: #ffffff;
            }

            img {
              filter: brightness(0) invert(1);
            }
          }
        }

        hr {
          width: 100%;
          border: 1px solid rgba(93, 184, 251, 0.42);
          margin-bottom: 30px;
        }
      }
    }
  }

  /*** MAIN CONTENT ***/
  .mainContent {
    padding: 0px;
    padding-top: 75px;
  }
}

/*** RESPONSIVE ON Mobile ***/
@media (max-width: 991px) {
  .privateTransaction {
    height: calc(100vh - 55px);
  }

  .mainContent {
    padding-bottom: 95px;
  }

  .menuPanelWrapper {
    height: 55px;
    padding: 5px 0;

    .mobile-topbar {
      height: 100%;
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 0 5px;
      float: right;
      align-items: center;
      align-self: center;
      align-content: center;

      .div-logo-tag {
        flex: 0 0 250px;

        img {
          max-width: 250px;
        }
      }

      .openMenu.btn-none {
        flex: 0 0 100px;
      }

      .div-btn-wallet {
        flex: 1 1 auto;

        .changeAccount {
          height: auto;

          .currentAccount {
            .accountName {
              font-weight: 500;
              font-size: 11px;
            }

            .accountBalance {
              font-weight: 500;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
</style>

<!-- GLOBAL CSS -->
<style lang="scss">
/*** IMPORTS ***/
@import "~@/assets/scss/mixins.scss";
@import "~@/assets/scss/colors.scss";

.privateLayout {
  .quickChargeBox {
    .changeWallet {
      .dropdown-toggle::after {
        position: absolute;
        right: 10px;
        top: 17px;
      }

      .dropdown-menu {
        width: 100%;
      }
    }
  }

  .menuPanel {
    .topBar {
      .changeAccount {
        .switchAccount {
          button {
            padding-left: 40px;
          }
        }
      }
    }

    .sideBar {
      .navLink {
        .toolIcon {
          width: 35px;
          height: auto;
        }

        .toolText {
          font-size: 0.8em;
          margin-top: 10px;
        }
      }
    }
  }

  .commandHistory {
    height: 100vh;

    .nav-tabs {
      border: none;

      .nav-item {
        width: 50%;
        text-align: center;

        .nav-link {
          border: none;
          color: white;
          background-color: transparent;
          position: relative;
          text-transform: capitalize;
        }

        .nav-link:after {
          content: " ";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: white;
          bottom: 0;
          left: 0;
        }

        .nav-link.active {
          font-weight: bold;
        }

        .nav-link.active:after {
          background-color: $primaryColor;
          height: 3px;
          bottom: -1px;
        }
      }
    }
  }
}

/*** RESPONSIVE ON PC ***/

.div-logo-tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 65px;
  padding-right: 25px;

  img {
    height: 100%;
    width: auto;
    padding: 5px 5px 5px 0;
    max-height: 70px;
    max-width: 300px;
    filter: drop-shadow(-3px 3px 1px rgb(204 204 204 / 20%));
  }
}

@media (min-width: 992px) {
  .menuPanelWrapper .menuPanel .sideBar {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .sideBarMobile {
    position: fixed;
    width: 100%;
    top: 0px;
    color: #fff;
    left: 0;
    padding: 20px;
    overflow-y: auto;
    z-index: 1;
    font-weight: 300;
    background: linear-gradient(90.13deg, #00041b 0%, #000937 100%);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex-direction: column;
    z-index: 99999;

    .navLink {
      margin: 10px 0;
    }

    .sideBar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;

      img {
        width: 120px;
      }

      p {
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 20px;
      }
    }

    h3 {
      font-weight: 500;
      margin-bottom: 30px;
      text-align: center;
    }

    .sideBar-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      width: 100%;
      padding: 10px 0px;
      margin-bottom: 15px;
      font-size: 14px;
      cursor: pointer;
      position: relative;

      img {
        margin-right: 10px;
      }

      p {
        margin-bottom: 0px;
        color: rgba(2, 225, 255, 0.33);
      }

      &.crash {
        background: linear-gradient(270deg,
            #116cd7 0%,
            rgba(49, 115, 193, 0) 92.71%);
        border-bottom: 2px solid #02e1ff;
        width: fit-content;
        padding: 0px 20px;
        margin: 0 auto 20px;

        img {
          width: 75px;
          height: auto;
          margin-bottom: 10px;
          filter: none !important;
        }

        p {
          color: #ffffff;
          font-size: 18px;
        }
      }

      &:hover {
        p {
          color: #ffffff;
        }

        img {
          filter: brightness(0) invert(1);
        }
      }

      &.router-link-active {
        background: linear-gradient(270deg,
            #116cd7 0%,
            rgba(49, 115, 193, 0) 92.71%);
        border-bottom: 2px solid #02e1ff;
        width: fit-content;
        margin: 0 auto 20px;
        padding: 5px 20px;
        border-radius: 5px;

        p {
          color: #ffffff;
        }

        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    hr {
      width: 100%;
      border: 1px solid rgba(93, 184, 251, 0.42);
      margin-bottom: 30px;
    }
  }
}

#carousel-event {
  .img-fluid {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .carousel-caption {
    position: relative;
    right: 0;
    left: 0;
  }

  .carousel-control-prev {
    left: -30px;
  }

  .carousel-control-next {
    right: -30px;
  }
}

#language {
  .box-language {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;

    .language-item {
      width: 48%;
      background: rgba(49, 49, 49, 0.71);
      margin-bottom: 10px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        background: #0ec5ff;
        color: rgba(49, 49, 49, 0.71);
      }

      &.active {
        background: #0ec5ff;
        color: rgba(49, 49, 49, 0.71);
      }

      img {
        width: 20px;
        height: auto;
        margin-right: 7px;
      }
    }
  }
}
</style>
